export default {
    login: "/auth/login",
    profile: "/users/me",
    accountList: "/accounts/list",
    accountDetail: "/accounts", // /accounts/{userId}
    updateAccountDetail: "/accounts/update",
    createAccountDetail: "/accounts/create",
    permissionList: "/accounts/permissions/list",
    updateFilterPreference: "/accounts/update/filter-preference",

    emailVerify: "/accounts/email-verify",  // /email-verify/{emailVerifyCode}
    emailVerifyConfirm: "/accounts/email-verify/confirm",
    forgotPassword: "/accounts/forgot-password", // /forgot-password/{email}
    verifyResetPasswordCode: "/accounts/reset-password",    // /reset-password/{resetPasswordCode}
    resetPasswordConfirm: "/accounts/reset-password/confirm",


    listCentre: "/centres/list",
    listClient: "/clients/list",
    listProduct: "/products/list",
    listCompany: "/companies/list",
    listFromCountry: "/k-parcel/list-from-country",
    listToCountry: "/k-parcel/list-to-country",

    // fulfillment management charts
    fulfillmentSummary: "/fulfillment/management/charts/summary",
    revenueChart: "/fulfillment/management/charts/revenue",
    gpChart: "/fulfillment/management/charts/gp",
    staffHeadcountChart: "/fulfillment/management/charts/staff-headcount",
    staffHeadcountClientChart: "/fulfillment/management/charts/staff-headcount-client",
    staffCostChart: "/fulfillment/management/charts/staff-cost",
    inboundVolumeChart: "/fulfillment/management/charts/inbound-volume",
    inboundVolumeClientChart: "/fulfillment/management/charts/inbound-volume-client",
    outboundVolumeChart: "/fulfillment/management/charts/outbound-volume",
    outboundVolumeClientChart: "/fulfillment/management/charts/outbound-volume-client",
    inventoryChart: "/fulfillment/management/charts/inventory",
    pickingAccuracyChart: "/fulfillment/management/charts/picking-accuracy",
    cycleCountChart: "/fulfillment/management/charts/cycle-count",
    adjustmentChart: "/fulfillment/management/charts/adjustment",
    adjustmentClientChart: "/fulfillment/management/charts/adjustment-client",
    shortageChart: "/fulfillment/management/charts/shortage",
    shortageClientChart: "/fulfillment/management/charts/shortage-client",
    damageChart: "/fulfillment/management/charts/damage",
    damageClientChart: "/fulfillment/management/charts/damage-client",
    storageSqFt: "/fulfillment/management/charts/storage-sqft",
    storageCapacity: "/fulfillment/management/charts/storage-capacity",
    revenueClientChart: "/fulfillment/management/charts/revenue-client",
    inventoryClientChart: "/fulfillment/management/charts/inventory-client",
    vasClientChart: "/fulfillment/management/charts/vas-client",

    // fulfillment operation charts
    fulfillmentOperationSummary: "/fulfillment/operation/charts/summary",

    outboundUnitsOrdersChart: "/fulfillment/operation/charts/outbound-units-orders",
    outboundHandledPerDayChart: "/fulfillment/operation/charts/outbound-handled-per-day",
    outboundFeeOrderEA: "/fulfillment/operation/charts/outbound-fee-order-ea",
    outboundParcelChart: "/fulfillment/operation/charts/outbound-parcel",

    outboundUnitChart: "/fulfillment/operation/charts/outbound-units",
    outboundUnitClientChart: "/fulfillment/operation/charts/outbound-units-client",
    outboundOrderChart: "/fulfillment/operation/charts/outbound-order",
    outboundOrderClientChart: "/fulfillment/operation/charts/outbound-order-client",
    outboundAvgUnitPerOrder: "/fulfillment/operation/charts/outbound-avg-unit-per-order",
    outboundAvgUnitPerOrderClient: "/fulfillment/operation/charts/outbound-avg-unit-per-order-client",
    inboundUnitChart: "/fulfillment/operation/charts/inbound-units",
    inboundUnitClientChart: "/fulfillment/operation/charts/inbound-units-client",

    // KPacel
    kParcelProductSummary: "/k-parcel/operation/charts/product-summary",

    kPacelShippedParcel: "/k-parcel/operation/charts/shipped-parcel",
    kPacelShippedParcelClient: "/k-parcel/operation/charts/shipped-parcel-client",
    kPacelShippedParcelTable: "/k-parcel/operation/charts/shipped-parcel-table",

    kPacelShippedWeight: "/k-parcel/operation/charts/shipped-weight",
    kPacelShippedWeightClient: "/k-parcel/operation/charts/shipped-weight-client",
    kPacelShippedWeightTable: "/k-parcel/operation/charts/shipped-weight-table",

    kParcelLeadTimeReport: "/k-parcel/operation/charts/lead-time-report",
    kParcelDeliveryPerformance: "/k-parcel/operation/charts/delivery-performance",

    kParcelProductList: "/k-parcel/list-product",
    kParcelDailyVolumes: "/k-parcel/statistics/daily-volumes",


    listOutboundOrder: "/twms/orders/outbound-order/list",


    // Sortation
    shopeeRelabel: "/shopee/relabel",
    productivityPerHour: "/sortation/real-time/productivity-per-hour",

    // Fufillment Accrual
    faSummary: "/fulfillment/accrual/charts/summary",
    faRevenueChart: "/fulfillment/accrual/charts/revenue",
    faRevenueClientChart: "/fulfillment/accrual/charts/revenue-client",
    faVASClientChart: "/fulfillment/accrual/charts/vas-client",
    faFTEAndPTECostChart: "/fulfillment/accrual/charts/fte-pte-cost",
    faFTEAndPTECostClientChart: "/fulfillment/accrual/charts/fte-pte-cost-client",
    faGPCompareChart: "/fulfillment/accrual/charts/gp-compare",
    faGPClientChart: "/fulfillment/accrual/charts/gp-client",


    // Management P&L
    mPnLSummary: "/management/p&l/summary",
    mPnLRevenue: "/management/p&l/revenue",
    mPnLOPEX: "/management/p&l/opex",
    mPnLEBIT: "/management/p&l/ebit",
    mPnLPAT: "/management/p&l/pat",
    mPnLPATAMI: "/management/p&l/patami",

    mPnLStaffCost: "/management/p&l/staff-cost",
    mPnLWarehouseCost: "/management/p&l/warehouse-cost",
    mPnLCBTCost: "/management/p&l/cbt-cost",
    mPnLOtherCost: "/management/p&l/other-cost",
    mPnLAdminCost: "/management/p&l/admin-cost",
    mPnLDepreciation: "/management/p&l/depreciation",
    mPnLInterest: "/management/p&l/interest",
    mPnLTax: "/management/p&l/tax",

    mPnLUploadExcel: "/management/p&l/upload-excel",


    // Jira
    jiraWeeklyTimeRecord: "/jira/tasks/weekly-time-record",
    jiraSearch: "/jira/tasks/search",

    // CRM-Contact
    crmGetContact: "/crm/contact",
    crmGetContactDetails: "/crm/contact", // /contacts/{{contactId}}
    crmAddContact: "/crm/contacts",
    crmUpdateContact: "/crm/contact", // /contacts/{{contactId}}
    crmDeleteContact: "/crm/contact", // /contacts/{{contactId}}

    //CRM-Pipeline
    crmGetPipeline: "/crm/pipeline",
    crmAddPipeline: "/crm/pipeline",
    crmDeletePipeline: "/crm/pipeline", //pipielines/{{pipelineId}}
    crmUpdatePipeline: "/crm/pipeline", //pipelines/{{pipelineId}}
    crmGetDealsOfPipeline: "/crm/pipeline", //pipelines/{{pipelineId}}/deals
    crmGetOrganizationOfPipeline: "/crm/pipeline",

    //CRM-Deals
    crmGetDeals: "/crm/deal",
    crmSearchDeals: "/crm/deal", //deals/search?term=<string>
    crmGetDealsDetails: "/crm/deal", //deals/{{dealId}}
    crmListProductOfDeals: "/crm/deal", //deals/{{dealId}}/products
    crmAddDeals: "/crm/deal",
    crmAddProductToDeals: "/crm/deal", //deals/{{dealId}}/products
    crmUpdateDeal: "/crm/deal", //deals/{{dealId}}
    crmUpdateProductDetailsOfDeal: "/crm/deal", //deals/{{dealId}}/products/{{productId}}
    crmDeleteDeals: "/crm/deal", //deals/{{dealId}}
    crmDeleteAttachedProduct: "/crm/deal", //deals/{{dealId}}/products/{{productId}}
    crmGetDealHistory: "/crm/deal", //deals/{{dealId}}/history
    crmPostDealHistory: "/crm/deal", //deals/{{dealId}}/history
    crmDeleteDeal: "/crm/deal", //deals/{{dealId}}

    //CRM-Products
    crmGetProducts: "/crm/product",
    crmAddProduct: "/crm/product",
    crmDeleteProduct: "/crm/product", //products/{{productId}}
    crmSyncProducts: "/crm/product/sync",
    crmMergeProducts: "/crm/product/merge",
    crmUpdateProduct: "/crm/product", //products/{{productId}}

    //CRM-Users
    crmGetUsers: "/crm/user",
    crmGetOneUser: "/crm/user", //users/{{uesrId}}
    crmAddUser: "/crm/user",
    crmUpdateUser: "/crm/user", //users/{{userId}}
    crmPutSearch:'/crm/user/search',
    crmPutUpdateUser:"/crm/user",
    crmPutPostUser:"/crm/user",
    crmGetMe:'/crm/user/me',
    crmGetMasterData:'/crm/master-data',
    crmPutMePipelineOrder: "/crm/user/me/pipeline-order",

    //CRM-Organization
    crmPostOrganization:'crm/organization',
    crmPutOrganization:'crm/organization',
    crmCheckOrganizationName:'crm/organization/check',

    crmGetOrganization: "/crm/organization",
    crmGetOrgDetails: "/organizations", //organizations/{{orgId}}
    crmAddOrganization: "/organizations",
    crmUpdateOrganization: "/organizations", //organizations/{{orgId}}
    crmDeleteOrganization: "/organizations", //organization/{{orgId}}
    crmMergeOrganization: "/crm/organization/merge",

    //CRM-Contact
    crmPostContacts:'/crm/contact',
    crmGetUser:'/crm/user',
    crmPutContact:'/crm/contact',

    //CRM-Stage
    crmGetStage: "/stages", //stages?pipeline_id=<integer>
    crmAddStage: "/stages",
    crmDeleteStage: "/stages", //stages/{{stage_id}}
    crmGetDealsOfStage: "/stages", //stages/{{stage_id}}
    crmUpdateStage: "/stages", //stages/{{stage_id}}

    //CRM-Roles
    crmGetRoles: "/crm/roles",

    //CRM-Roles
    getUsers: "/users",

    //ERP
    erpGetUsers: "/erp/user",
    erpGetUser:'/erp/user',
    erpPutUpdateUser:"/erp/user",
    erpPutPostUser:"/erp/user",
    erpGetRoles: "/erp/roles",
    erpGetPermissions: "/erp/permissions",

    erpPostRole:"/erp/roles",
    erpPutRole:"/erp/roles",

    erpChargeCodes: '/erp/charge-code',
    erpCurrencyProfiles: '/erp/currency-profile',
    erpCurrencyProfileItems: '/erp/currency-profile-item',
    erpEmailTemplates: '/erp/email-template',
    erpProcessCurrencyProfileItems: '/erp/currency-profile-item/process',
    erpOrganizationProfile: '/erp/organization-profile',
    erpOrganizationProfileDraft: '/erp/organization-profile/draft',
    erpBankStatementItems: '/erp/bank-statement-item',
    erpProcessBankStatementItems: '/erp/bank-statement-item/process',
    erpIncomingActualReceivable: '/erp/incoming-actual-receivable',
    erpIncomingActualPayable: '/erp/incoming-actual-payable',
    erpIncomingActualPayableSummary: '/erp/incoming-actual-payable-summary',
    erpProcessChargeItems: '/erp/charge-item/process',
    erpBankAccounts: '/erp/bank-account',
    erpInvoices: '/erp/invoice',
    erpInvoiceItems: '/erp/invoice-item',
    erpInvoiceCreateManual: '/erp/invoice/create/manual',
    erpInvoiceDownload: '/erp/invoice/pdf',
    erpAccruals: '/erp/accrual',
    erpWarehouseCodeMappings: '/erp/warehouse-code-mapping',
    erpProcessAccruals: '/erp/accrual/process',
    erpProcessActualPayableSummaries: '/erp/incoming-actual-payable-summary/process',

    erpFileUpload: '/erp/file/upload',
    erpFileDownload: '/erp/file/download',

    erpGetMasterData:'/erp/master-data',

    erpGetMe:'/erp/user/me',

    dynamicChart: "/dynamic-chart"
}
